const list = [
    {
        "id": 0,
        "char": "~",
        "count": 0
    },
    {
        "id": 1,
        "char": "!",
        "count": 0
    },
    {
        "id": 2,
        "char": "@",
        "count": 0
    },
    {
        "id": 3,
        "char": "#",
        "count": 0
    },
    {
        "id": 4,
        "char": "$",
        "count": 0
    },
    {
        "id": 5,
        "char": "%",
        "count": 0
    },
    {
        "id": 6,
        "char": "^",
        "count": 0
    },
    {
        "id": 7,
        "char": "&",
        "count": 0
    },
    {
        "id": 8,
        "char": "*",
        "count": 0
    },
    {
        "id": 9,
        "char": "\"",
        "count": 0
    },
    {
        "id": 10,
        "char": "+",
        "count": 0
    },
    {
        "id": 11,
        "char": "=",
        "count": 0
    },
    {
        "id": 12,
        "char": "`",
        "count": 0
    },
    {
        "id": 13,
        "char": "|",
        "count": 0
    },
    {
        "id": 14,
        "char": "(",
        "count": 0
    },
    {
        "id": 15,
        "char": ")",
        "count": 0
    },
    {
        "id": 16,
        "char": "[",
        "count": 0
    },
    {
        "id": 17,
        "char": "]",
        "count": 0
    },
    {
        "id": 18,
        "char": "{",
        "count": 0
    },
    {
        "id": 19,
        "char": "}",
        "count": 0
    },
    {
        "id": 20,
        "char": ":",
        "count": 0
    },
    {
        "id": 21,
        "char": ";",
        "count": 0
    },
    {
        "id": 22,
        "char": "-",
        "count": 0
    },
    {
        "id": 23,
        "char": "_",
        "count": 0
    },
    {
        "id": 24,
        "char": "-",
        "count": 0
    },
    {
        "id": 25,
        "char": "＃",
        "count": 0
    },
    {
        "id": 26,
        "char": "＆",
        "count": 0
    },
    {
        "id": 27,
        "char": "＆",
        "count": 0
    },
    {
        "id": 28,
        "char": "＠",
        "count": 0
    },
    {
        "id": 29,
        "char": "§",
        "count": 0
    },
    {
        "id": 30,
        "char": "※",
        "count": 0
    },
    {
        "id": 31,
        "char": "☆",
        "count": 0
    },
    {
        "id": 32,
        "char": "★",
        "count": 0
    },
    {
        "id": 33,
        "char": "○",
        "count": 0
    },
    {
        "id": 34,
        "char": "●",
        "count": 0
    },
    {
        "id": 35,
        "char": "◎",
        "count": 0
    },
    {
        "id": 36,
        "char": "◇",
        "count": 0
    },
    {
        "id": 37,
        "char": "◆",
        "count": 0
    },
    {
        "id": 38,
        "char": "□",
        "count": 0
    },
    {
        "id": 39,
        "char": "■",
        "count": 0
    },
    {
        "id": 40,
        "char": "△",
        "count": 0
    },
    {
        "id": 41,
        "char": "▲",
        "count": 0
    },
    {
        "id": 42,
        "char": "▽",
        "count": 0
    },
    {
        "id": 43,
        "char": "▼",
        "count": 0
    },
    {
        "id": 44,
        "char": "→",
        "count": 0
    },
    {
        "id": 45,
        "char": "←",
        "count": 0
    },
    {
        "id": 46,
        "char": "←",
        "count": 0
    },
    {
        "id": 47,
        "char": "↑",
        "count": 0
    },
    {
        "id": 48,
        "char": "↓",
        "count": 0
    },
    {
        "id": 49,
        "char": "↔",
        "count": 0
    },
    {
        "id": 50,
        "char": "〓",
        "count": 0
    },
    {
        "id": 51,
        "char": "◁",
        "count": 0
    },
    {
        "id": 52,
        "char": "◀",
        "count": 0
    },
    {
        "id": 53,
        "char": "▷",
        "count": 0
    },
    {
        "id": 54,
        "char": "▶",
        "count": 0
    },
    {
        "id": 55,
        "char": "♤",
        "count": 0
    },
    {
        "id": 56,
        "char": "♠",
        "count": 0
    },
    {
        "id": 57,
        "char": "♡",
        "count": 0
    },
    {
        "id": 58,
        "char": "♥",
        "count": 0
    },
    {
        "id": 59,
        "char": "♧",
        "count": 0
    },
    {
        "id": 60,
        "char": "♣",
        "count": 0
    },
    {
        "id": 61,
        "char": "⊙",
        "count": 0
    },
    {
        "id": 62,
        "char": "◈",
        "count": 0
    },
    {
        "id": 63,
        "char": "▣",
        "count": 0
    },
    {
        "id": 64,
        "char": "◐",
        "count": 0
    },
    {
        "id": 65,
        "char": "◑",
        "count": 0
    },
    {
        "id": 66,
        "char": "▒",
        "count": 0
    },
    {
        "id": 67,
        "char": "▤",
        "count": 0
    },
    {
        "id": 68,
        "char": "▥",
        "count": 0
    },
    {
        "id": 69,
        "char": "▨",
        "count": 0
    },
    {
        "id": 70,
        "char": "▧",
        "count": 0
    },
    {
        "id": 71,
        "char": "▦",
        "count": 0
    },
    {
        "id": 72,
        "char": "▩",
        "count": 0
    },
    {
        "id": 73,
        "char": "♨",
        "count": 0
    },
    {
        "id": 74,
        "char": "☏",
        "count": 0
    },
    {
        "id": 75,
        "char": "☎",
        "count": 0
    },
    {
        "id": 76,
        "char": "☜",
        "count": 0
    },
    {
        "id": 77,
        "char": "☞",
        "count": 0
    },
    {
        "id": 78,
        "char": "¶",
        "count": 0
    },
    {
        "id": 79,
        "char": "†",
        "count": 0
    },
    {
        "id": 80,
        "char": "‡",
        "count": 0
    },
    {
        "id": 81,
        "char": "↕",
        "count": 0
    },
    {
        "id": 82,
        "char": "↗",
        "count": 0
    },
    {
        "id": 83,
        "char": "↙",
        "count": 0
    },
    {
        "id": 84,
        "char": "↖",
        "count": 0
    },
    {
        "id": 85,
        "char": "↘",
        "count": 0
    },
    {
        "id": 86,
        "char": "♭",
        "count": 0
    },
    {
        "id": 87,
        "char": "♩",
        "count": 0
    },
    {
        "id": 88,
        "char": "♪",
        "count": 0
    },
    {
        "id": 89,
        "char": "♬",
        "count": 0
    },
    {
        "id": 90,
        "char": "㉿",
        "count": 0
    },
    {
        "id": 91,
        "char": "㈜№",
        "count": 0
    },
    {
        "id": 92,
        "char": "㏇",
        "count": 0
    },
    {
        "id": 93,
        "char": "™",
        "count": 0
    },
    {
        "id": 94,
        "char": "㏂",
        "count": 0
    },
    {
        "id": 95,
        "char": "㏘",
        "count": 0
    },
    {
        "id": 96,
        "char": "℡",
        "count": 0
    },
    {
        "id": 97,
        "char": "®",
        "count": 0
    },
    {
        "id": 98,
        "char": "ª",
        "count": 0
    },
    {
        "id": 99,
        "char": "º",
        "count": 0
    },
    {
        "id": 100,
        "char": "─",
        "count": 0
    },
    {
        "id": 101,
        "char": "│",
        "count": 0
    },
    {
        "id": 102,
        "char": "┌",
        "count": 0
    },
    {
        "id": 103,
        "char": "┐",
        "count": 0
    },
    {
        "id": 104,
        "char": "┘",
        "count": 0
    },
    {
        "id": 105,
        "char": "└",
        "count": 0
    },
    {
        "id": 106,
        "char": "├",
        "count": 0
    },
    {
        "id": 107,
        "char": "┬",
        "count": 0
    },
    {
        "id": 108,
        "char": "┤",
        "count": 0
    },
    {
        "id": 109,
        "char": "┴",
        "count": 0
    },
    {
        "id": 110,
        "char": "┼",
        "count": 0
    },
    {
        "id": 111,
        "char": "━",
        "count": 0
    },
    {
        "id": 112,
        "char": "┃",
        "count": 0
    },
    {
        "id": 113,
        "char": "┏",
        "count": 0
    },
    {
        "id": 114,
        "char": "┓",
        "count": 0
    },
    {
        "id": 115,
        "char": "┛",
        "count": 0
    },
    {
        "id": 116,
        "char": "┗",
        "count": 0
    },
    {
        "id": 117,
        "char": "┣",
        "count": 0
    },
    {
        "id": 118,
        "char": "┳",
        "count": 0
    },
    {
        "id": 119,
        "char": "┫",
        "count": 0
    },
    {
        "id": 120,
        "char": "┻",
        "count": 0
    },
    {
        "id": 121,
        "char": "╋",
        "count": 0
    },
    {
        "id": 122,
        "char": "┠",
        "count": 0
    },
    {
        "id": 123,
        "char": "┯",
        "count": 0
    },
    {
        "id": 124,
        "char": "┨",
        "count": 0
    },
    {
        "id": 125,
        "char": "┷",
        "count": 0
    },
    {
        "id": 126,
        "char": "┿",
        "count": 0
    },
    {
        "id": 127,
        "char": "┝",
        "count": 0
    },
    {
        "id": 128,
        "char": "┰",
        "count": 0
    },
    {
        "id": 129,
        "char": "┥",
        "count": 0
    },
    {
        "id": 130,
        "char": "┸",
        "count": 0
    },
    {
        "id": 131,
        "char": "╂",
        "count": 0
    },
    {
        "id": 132,
        "char": "┒┑",
        "count": 0
    },
    {
        "id": 133,
        "char": "┚",
        "count": 0
    },
    {
        "id": 134,
        "char": "┙",
        "count": 0
    },
    {
        "id": 135,
        "char": "┖",
        "count": 0
    },
    {
        "id": 136,
        "char": "┕",
        "count": 0
    },
    {
        "id": 137,
        "char": "┎",
        "count": 0
    },
    {
        "id": 138,
        "char": "┍",
        "count": 0
    },
    {
        "id": 139,
        "char": "┞",
        "count": 0
    },
    {
        "id": 140,
        "char": "┟",
        "count": 0
    },
    {
        "id": 141,
        "char": "┡",
        "count": 0
    },
    {
        "id": 142,
        "char": "┢",
        "count": 0
    },
    {
        "id": 143,
        "char": "┦",
        "count": 0
    },
    {
        "id": 144,
        "char": "┧",
        "count": 0
    },
    {
        "id": 145,
        "char": "┩",
        "count": 0
    },
    {
        "id": 146,
        "char": "┪",
        "count": 0
    },
    {
        "id": 147,
        "char": "┭",
        "count": 0
    },
    {
        "id": 148,
        "char": "┮",
        "count": 0
    },
    {
        "id": 149,
        "char": "┱",
        "count": 0
    },
    {
        "id": 150,
        "char": "┲",
        "count": 0
    },
    {
        "id": 151,
        "char": "┵",
        "count": 0
    },
    {
        "id": 152,
        "char": "┶",
        "count": 0
    },
    {
        "id": 153,
        "char": "┹",
        "count": 0
    },
    {
        "id": 154,
        "char": "┺",
        "count": 0
    },
    {
        "id": 155,
        "char": "┽",
        "count": 0
    },
    {
        "id": 156,
        "char": "┾",
        "count": 0
    },
    {
        "id": 157,
        "char": "╀",
        "count": 0
    },
    {
        "id": 158,
        "char": "╁",
        "count": 0
    },
    {
        "id": 159,
        "char": "╃",
        "count": 0
    },
    {
        "id": 160,
        "char": "╄",
        "count": 0
    },
    {
        "id": 161,
        "char": "╅",
        "count": 0
    },
    {
        "id": 162,
        "char": "╆",
        "count": 0
    },
    {
        "id": 163,
        "char": "╇",
        "count": 0
    },
    {
        "id": 164,
        "char": "╈",
        "count": 0
    },
    {
        "id": 165,
        "char": "╉",
        "count": 0
    },
    {
        "id": 166,
        "char": "╊＋",
        "count": 0
    },
    {
        "id": 167,
        "char": "－",
        "count": 0
    },
    {
        "id": 168,
        "char": "＜",
        "count": 0
    },
    {
        "id": 169,
        "char": "＝",
        "count": 0
    },
    {
        "id": 170,
        "char": "＞",
        "count": 0
    },
    {
        "id": 171,
        "char": "±",
        "count": 0
    },
    {
        "id": 172,
        "char": "×",
        "count": 0
    },
    {
        "id": 173,
        "char": "÷",
        "count": 0
    },
    {
        "id": 174,
        "char": "≠",
        "count": 0
    },
    {
        "id": 175,
        "char": "≤",
        "count": 0
    },
    {
        "id": 176,
        "char": "≥",
        "count": 0
    },
    {
        "id": 177,
        "char": "∞",
        "count": 0
    },
    {
        "id": 178,
        "char": "∴",
        "count": 0
    },
    {
        "id": 179,
        "char": "♂",
        "count": 0
    },
    {
        "id": 180,
        "char": "♀",
        "count": 0
    },
    {
        "id": 181,
        "char": "∠",
        "count": 0
    },
    {
        "id": 182,
        "char": "⊥",
        "count": 0
    },
    {
        "id": 183,
        "char": "⌒",
        "count": 0
    },
    {
        "id": 184,
        "char": "∂",
        "count": 0
    },
    {
        "id": 185,
        "char": "∇",
        "count": 0
    },
    {
        "id": 186,
        "char": "≡",
        "count": 0
    },
    {
        "id": 187,
        "char": "≒",
        "count": 0
    },
    {
        "id": 188,
        "char": "≪",
        "count": 0
    },
    {
        "id": 189,
        "char": "≫",
        "count": 0
    },
    {
        "id": 190,
        "char": "√",
        "count": 0
    },
    {
        "id": 191,
        "char": "∽",
        "count": 0
    },
    {
        "id": 192,
        "char": "∝",
        "count": 0
    },
    {
        "id": 193,
        "char": "∵",
        "count": 0
    },
    {
        "id": 194,
        "char": "∫",
        "count": 0
    },
    {
        "id": 195,
        "char": "∬",
        "count": 0
    },
    {
        "id": 196,
        "char": "∈",
        "count": 0
    },
    {
        "id": 197,
        "char": "∋",
        "count": 0
    },
    {
        "id": 198,
        "char": "⊆",
        "count": 0
    },
    {
        "id": 199,
        "char": "⊇",
        "count": 0
    },
    {
        "id": 200,
        "char": "⊂",
        "count": 0
    },
    {
        "id": 201,
        "char": "⊃",
        "count": 0
    },
    {
        "id": 202,
        "char": "∪",
        "count": 0
    },
    {
        "id": 203,
        "char": "∩",
        "count": 0
    },
    {
        "id": 204,
        "char": "∧",
        "count": 0
    },
    {
        "id": 205,
        "char": "∨",
        "count": 0
    },
    {
        "id": 206,
        "char": "￢",
        "count": 0
    },
    {
        "id": 207,
        "char": "⇒",
        "count": 0
    },
    {
        "id": 208,
        "char": "⇔",
        "count": 0
    },
    {
        "id": 209,
        "char": "∀",
        "count": 0
    },
    {
        "id": 210,
        "char": "∃",
        "count": 0
    },
    {
        "id": 211,
        "char": "∮",
        "count": 0
    },
    {
        "id": 212,
        "char": "∑",
        "count": 0
    },
    {
        "id": 213,
        "char": "∏！",
        "count": 0
    },
    {
        "id": 214,
        "char": "＇",
        "count": 0
    },
    {
        "id": 215,
        "char": "，",
        "count": 0
    },
    {
        "id": 216,
        "char": "．",
        "count": 0
    },
    {
        "id": 217,
        "char": "／",
        "count": 0
    },
    {
        "id": 218,
        "char": "：",
        "count": 0
    },
    {
        "id": 219,
        "char": "；",
        "count": 0
    },
    {
        "id": 220,
        "char": "？",
        "count": 0
    },
    {
        "id": 221,
        "char": "＾",
        "count": 0
    },
    {
        "id": 222,
        "char": "＿",
        "count": 0
    },
    {
        "id": 223,
        "char": "｀",
        "count": 0
    },
    {
        "id": 224,
        "char": "｜",
        "count": 0
    },
    {
        "id": 225,
        "char": "￣",
        "count": 0
    },
    {
        "id": 226,
        "char": "、",
        "count": 0
    },
    {
        "id": 227,
        "char": "。",
        "count": 0
    },
    {
        "id": 228,
        "char": "·",
        "count": 0
    },
    {
        "id": 229,
        "char": "‥",
        "count": 0
    },
    {
        "id": 230,
        "char": "…",
        "count": 0
    },
    {
        "id": 231,
        "char": "¨",
        "count": 0
    },
    {
        "id": 232,
        "char": "〃",
        "count": 0
    },
    {
        "id": 233,
        "char": "­",
        "count": 0
    },
    {
        "id": 234,
        "char": "―",
        "count": 0
    },
    {
        "id": 235,
        "char": "∥",
        "count": 0
    },
    {
        "id": 236,
        "char": "＼",
        "count": 0
    },
    {
        "id": 237,
        "char": "∼",
        "count": 0
    },
    {
        "id": 238,
        "char": "´",
        "count": 0
    },
    {
        "id": 239,
        "char": "～",
        "count": 0
    },
    {
        "id": 240,
        "char": "ˇ",
        "count": 0
    },
    {
        "id": 241,
        "char": "˘",
        "count": 0
    },
    {
        "id": 242,
        "char": "˝",
        "count": 0
    },
    {
        "id": 243,
        "char": "˚",
        "count": 0
    },
    {
        "id": 244,
        "char": "˙",
        "count": 0
    },
    {
        "id": 245,
        "char": "¸",
        "count": 0
    },
    {
        "id": 246,
        "char": "˛",
        "count": 0
    },
    {
        "id": 247,
        "char": "¡",
        "count": 0
    },
    {
        "id": 248,
        "char": "¿",
        "count": 0
    },
    {
        "id": 249,
        "char": "ː",
        "count": 0
    }
]

export default list