import list from './list.js'

//-------------- variables --------------

// HTML elemnts
const charCon = document.querySelector('#charCon')
const topCon = document.querySelector('#topCon')
const toast = document.querySelector('#toast')
const sortBtns = document.querySelectorAll('.sortBtn')
const title = document.querySelector('#title')

// key values
const pointCol = 'rgba(236, 199, 67,'
const charDivSize = 120
const charDivSizeM = 70
const bumper = 50
const bumperM = 10


//-------------- funtions --------------

// padNumber
const padNumber = (number, length) => {
    return String(number).padStart(length, '0');
}

// write clipboard
const writeClipboard = async (text) => {
    try{
        await navigator.clipboard.writeText(text)
    } catch (error) {
        console.log(error.message)
    }
}

// Draw Table
const drawTable = () => {
    let clearToast
    for(let i = 0; i < list.length; i ++){
        const charDiv = document.createElement('div')
        const charBadge = document.createElement('div')
    
        charDiv.className = 'charDiv'
        charDiv.innerHTML = list[i].char
        charDiv.id = 'charDiv' + list[i].id
        charBadge.className = 'charBadge'
        charBadge.innerHTML = padNumber(list[i].id + 1, 3)
    
        const alpha = list[i].count / 10
        charDiv.style['background-color'] = pointCol+alpha+')'
    
        charCon.append(charDiv)
        charDiv.append(charBadge)
    
        charDiv.onclick = () => {
            // copy text
            navigator.clipboard.writeText(list[i].char)
    
            // record count
            list[i].count ++
            const alpha2 = list[i].count / 10
            const target = document.querySelector('#charDiv' + list[i].id)
            target.style['background-color'] = pointCol+alpha2+')'

            // save to localStorage
            listToLog()
    
            //toast control
            toast.style['bottom'] = 50+'px'
            clearTimeout(clearToast)
            clearToast = setTimeout(() => {
                toast.style['bottom'] = -50+'px'
            }, 2000)
        }

        charDiv.ontouchend = (e) => {
            e.preventDefault()

            // copy text
            writeClipboard(list[i].char)
    
            // record count
            list[i].count ++
            const alpha2 = list[i].count / 10
            const target = document.querySelector('#charDiv' + list[i].id)
            target.style['background-color'] = pointCol+alpha2+')'

            // save to localStorage
            listToLog()
    
            //toast control
            toast.style['bottom'] = 50+'px'
            clearTimeout(clearToast)
            clearToast = setTimeout(() => {
                toast.style['bottom'] = -50+'px'
            }, 2000)
        }
    }
}

//--------------sort--------------

const sortBy = (mode) => {

    while(charCon.firstChild){ // remove table
        charCon.firstChild.remove()
    }

    if(mode == 'default'){
        sortBtns[0].className = 'sortBtn selected'
        sortBtns[1].className = 'sortBtn'

        list.sort((a, b) => a.id - b.id);
    } else if(mode == 'mostused'){
        sortBtns[1].className = 'sortBtn selected'
        sortBtns[0].className = 'sortBtn'

        list.sort((a, b) => {
            if (b.count !== a.count) {
                return b.count - a.count;
            } else {
                return a.id - b.id;
            }
        });
    }

    localStorage.setItem('logSort', mode)
    drawTable()
}

sortBtns[0].onclick = () => sortBy('default')
sortBtns[1].onclick = () => sortBy('mostused')

//  list를 log String 포맷으로 변환 (count 변동 있을 때마다 호출)
const listToLog = () => {
    let str = ''
    for(let i = 0; i < list.length; i ++){
        if(list[i].count > 0){
            const ns = list[i].id + ':' + list[i].count

            if(str.length == 0){
                str += ns
            } else {
                str += '/'+ns
            }
        }
    }
    localStorage.setItem('logString', str)
}

// --------------sort--------------

// logString을 list에 반영 (최초 1번만 호출)
let logString = localStorage.getItem('logString')
if(logString == null){
    logString = ''
} else {
    let logArray = logString.split('/')
    for(let i = 0; i < logArray.length; i ++){
        const splitByCount = logArray[i].split(':')
        const foundItem = list.find(item=>item.id == splitByCount[0])
        if(foundItem){
            foundItem.count = Number(splitByCount[1])
        }
    }
}

// 마지막 sort 방식을 불러옴
let logSort = localStorage.getItem('logSort')
if(logSort == null){
    logSort = ''
    drawTable()
} else {
    sortBy(logSort)
}

// layout
const resize = () => {

    let width = document.body.clientWidth
    let bumperVal
    let divSize

    if(width < 600){
        bumperVal = bumperM
        divSize = charDivSizeM
        title.style['max-width'] = 300 + 'px'
    } else {
        bumperVal = bumper
        divSize = charDivSize
        title.style['max-width'] = 600 + 'px'
    }

    let pageMargin = ((document.body.clientWidth - bumperVal * 2) % divSize) / 2

    charCon.style['margin-left'] = pageMargin + 'px'
    topCon.style['margin'] = '0px ' + pageMargin + 'px'

}

window.onresize = () => {
    resize()
}

resize()


//[] 모바일 버전
//[] 도메인 구매
//[] 캐시정책 확인